export const FilterIcon = () => {
  return (
    <span className="text-color-5AC063">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-funnel"
        viewBox="0 0 16 16"
      >
        <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z"></path>
      </svg>
    </span>
  );
};

export const ExportIcon = () => {
  return (
    <span className="text-color-5AC063">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-box-arrow-up-right"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
        ></path>
        <path
          fillRule="evenodd"
          d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
        ></path>
      </svg>
    </span>
  );
};

export const CheckListIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="18px"
      height="18px"
      fill={fill}
    >
      <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32l224 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-224 0c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32l224 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-224 0c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32l288 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-288 0c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
    </svg>
  );
};

export const Credentials = ({ fill, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={width}
      height={height}
      fill={fill}
    >
      <path d="M 20 3 C 15.054688 3 11 7.054688 11 12 C 11 12.519531 11.085938 12.976563 11.15625 13.4375 L 3.28125 21.28125 L 3 21.59375 L 3 29 L 10 29 L 10 26 L 13 26 L 13 23 L 16 23 L 16 20.03125 C 17.179688 20.609375 18.554688 21 20 21 C 24.945313 21 29 16.945313 29 12 C 29 7.054688 24.945313 3 20 3 Z M 20 5 C 23.855469 5 27 8.144531 27 12 C 27 15.855469 23.855469 19 20 19 C 18.789063 19 17.542969 18.644531 16.59375 18.125 L 16.34375 18 L 14 18 L 14 21 L 11 21 L 11 24 L 8 24 L 8 27 L 5 27 L 5 22.4375 L 12.90625 14.5 L 13.28125 14.15625 L 13.1875 13.625 C 13.085938 13.023438 13 12.488281 13 12 C 13 8.144531 16.144531 5 20 5 Z M 22 8 C 20.894531 8 20 8.894531 20 10 C 20 11.105469 20.894531 12 22 12 C 23.105469 12 24 11.105469 24 10 C 24 8.894531 23.105469 8 22 8 Z" />
    </svg>
  );
};

export const Logout = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="18px"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#EF4444"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-log-out"
    >
      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
      <polyline points="16 17 21 12 16 7"></polyline>
      <line x1="21" y1="12" x2="9" y2="12"></line>
    </svg>
  );
};

export const MailIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4L8.8906 9.2604C9.5624 9.70827 10.4376 9.70827 11.1094 9.2604L19 4M3 15H17C18.1046 15 19 14.1046 19 13V3C19 1.89543 18.1046 1 17 1H3C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15Z"
        stroke="#5C5F62"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export function ChartColumnSvg() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.39995 4.79999C5.06495 4.79999 5.59995 5.33499 5.59995 5.99999V23.6C5.59995 24.265 6.13495 24.8 6.79995 24.8H27.6C28.265 24.8 28.8 25.335 28.8 26C28.8 26.665 28.265 27.2 27.6 27.2H6.79995C4.80995 27.2 3.19995 25.59 3.19995 23.6V5.99999C3.19995 5.33499 3.73495 4.79999 4.39995 4.79999ZM11.6 14.4C12.265 14.4 12.8 14.935 12.8 15.6V19.6C12.8 20.265 12.265 20.8 11.6 20.8C10.935 20.8 10.4 20.265 10.4 19.6V15.6C10.4 14.935 10.935 14.4 11.6 14.4ZM17.6 10.8V19.6C17.6 20.265 17.065 20.8 16.4 20.8C15.735 20.8 15.2 20.265 15.2 19.6V10.8C15.2 10.135 15.735 9.59999 16.4 9.59999C17.065 9.59999 17.6 10.135 17.6 10.8ZM21.2 12.8C21.865 12.8 22.4 13.335 22.4 14V19.6C22.4 20.265 21.865 20.8 21.2 20.8C20.535 20.8 20 20.265 20 19.6V14C20 13.335 20.535 12.8 21.2 12.8ZM27.2 7.59999V19.6C27.2 20.265 26.665 20.8 26 20.8C25.335 20.8 24.8 20.265 24.8 19.6V7.59999C24.8 6.93499 25.335 6.39999 26 6.39999C26.665 6.39999 27.2 6.93499 27.2 7.59999Z"
        fill="#8C9196"
      />
    </svg>
  );
}

export function ChartPieSvg() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8 15.2V4.03C16.8 3.58 17.15 3.2 17.6 3.2C23.785 3.2 28.8 8.215 28.8 14.4C28.8 14.85 28.42 15.2 27.97 15.2H16.8ZM3.19995 16.8C3.19995 10.735 7.70495 5.715 13.55 4.915C14.01 4.85 14.4 5.22 14.4 5.685V17.6L22.225 25.425C22.56 25.76 22.535 26.31 22.15 26.58C20.19 27.98 17.79 28.8 15.2 28.8C8.57495 28.8 3.19995 23.43 3.19995 16.8ZM29.52 17.6C29.985 17.6 30.35 17.99 30.29 18.45C29.905 21.245 28.56 23.73 26.595 25.565C26.295 25.845 25.825 25.825 25.535 25.53L17.6 17.6H29.52Z"
        fill="#8C9196"
      />
    </svg>
  );
}

export function MailSent() {
  return (
    <svg
      width="234"
      height="207"
      viewBox="0 0 234 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M234 81.7465L27.6844 111.449L3.05176e-05 139.387L234 81.7465Z"
        fill="#008080"
      />
      <path
        opacity="0.6"
        d="M234 81.7465L27.6844 111.449L3.05176e-05 139.387L234 81.7465Z"
        fill="white"
      />
      <path
        d="M234 81.7465L27.6844 111.449L37.2594 151.849L234 81.7465Z"
        fill="#008080"
      />
      <path
        opacity="0.2"
        d="M234 81.7465L27.6844 111.449L37.2594 151.849L234 81.7465Z"
        fill="black"
      />
      <path
        d="M41.4949 34.9754L29.5216 91.0862C29.2101 92.5514 29.4926 94.0803 30.3069 95.3375C31.1213 96.5947 32.4011 97.4775 33.8656 97.7923L140.729 120.599C142.194 120.908 143.722 120.624 144.979 119.81C146.235 118.996 147.119 117.718 147.436 116.255L159.418 60.1438C159.727 58.6776 159.442 57.1487 158.626 55.8919C157.81 54.635 156.53 53.7526 155.065 53.4377L48.201 30.6314C46.7347 30.3226 45.2059 30.6073 43.9491 31.4232C42.6922 32.239 41.8098 33.5195 41.4949 34.9845V34.9754Z"
        fill="#008080"
      />
      <path
        opacity="0.4"
        d="M41.4949 34.9754L29.5216 91.0862C29.2101 92.5514 29.4926 94.0803 30.3069 95.3375C31.1213 96.5947 32.4011 97.4775 33.8656 97.7923L140.729 120.599C142.194 120.908 143.722 120.624 144.979 119.81C146.235 118.996 147.119 117.718 147.436 116.255L159.418 60.1438C159.727 58.6776 159.442 57.1487 158.626 55.8919C157.81 54.635 156.53 53.7526 155.065 53.4377L48.201 30.6314C46.7347 30.3226 45.2059 30.6073 43.9491 31.4232C42.6922 32.239 41.8098 33.5195 41.4949 34.9845V34.9754Z"
        fill="black"
      />
      <path
        d="M44.1285 31.3011L79.8312 75.1308C83.1424 79.1944 87.7415 82.006 92.8678 83.1004C97.9941 84.1948 103.34 83.5064 108.022 81.1491L158.513 55.7183C157.696 54.5449 156.463 53.7263 155.065 53.4286L48.2011 30.6223C46.8065 30.3255 45.3514 30.568 44.1285 31.3011Z"
        fill="#008080"
      />
      <path
        d="M234 81.7465L63.8305 122.191L37.2594 151.849L234 81.7465Z"
        fill="#008080"
      />
      <path
        opacity="0.6"
        d="M234 81.7465L63.8305 122.191L37.2594 151.849L234 81.7465Z"
        fill="white"
      />
      <path
        d="M171.926 120.73L172.384 121.511L153.447 132.603L152.989 131.823L171.926 120.73Z"
        fill="#008080"
      />
      <path
        d="M144.628 136.713L145.086 137.494L138.276 141.482L137.819 140.702L144.628 136.713Z"
        fill="#008080"
      />
      <path
        d="M234 81.7465L63.8305 122.191L96.9087 162.057L234 81.7465Z"
        fill="#008080"
      />
      <path
        d="M6.38952 176.501C6.61876 176.502 6.84602 176.459 7.05922 176.375L34.7707 165.406C35.214 165.227 35.5688 164.88 35.7574 164.441C35.9461 164.002 35.9534 163.506 35.7777 163.062C35.6021 162.617 35.2577 162.26 34.8198 162.069C34.3819 161.877 33.886 161.866 33.4403 162.039L5.71979 173.008C5.32763 173.163 5.0019 173.45 4.79881 173.819C4.59573 174.189 4.52802 174.618 4.60742 175.032C4.68681 175.446 4.90831 175.82 5.23367 176.088C5.55903 176.356 5.96784 176.502 6.38952 176.501Z"
        fill="#008080"
      />
      <path
        opacity="0.3"
        d="M6.38952 176.501C6.61876 176.502 6.84602 176.459 7.05922 176.375L34.7707 165.406C35.214 165.227 35.5688 164.88 35.7574 164.441C35.9461 164.002 35.9534 163.506 35.7777 163.062C35.6021 162.617 35.2577 162.26 34.8198 162.069C34.3819 161.877 33.886 161.866 33.4403 162.039L5.71979 173.008C5.32763 173.163 5.0019 173.45 4.79881 173.819C4.59573 174.189 4.52802 174.618 4.60742 175.032C4.68681 175.446 4.90831 175.82 5.23367 176.088C5.55903 176.356 5.96784 176.502 6.38952 176.501Z"
        fill="black"
      />
      <path
        d="M8.02752 160.609C8.25452 160.608 8.47899 160.562 8.68816 160.473L23.5756 154.546C23.7982 154.459 24.0015 154.33 24.1738 154.165C24.3462 154 24.4842 153.802 24.5799 153.583C24.6755 153.365 24.727 153.129 24.7313 152.891C24.7356 152.652 24.6926 152.415 24.6049 152.193C24.5172 151.971 24.3864 151.768 24.2201 151.597C24.0538 151.426 23.8553 151.289 23.636 151.195C23.4167 151.101 23.1809 151.051 22.9422 151.048C22.7035 151.045 22.4667 151.09 22.2453 151.179L7.35779 157.08C6.91164 157.255 6.5534 157.601 6.36167 158.04C6.16994 158.48 6.16038 158.977 6.33512 159.424C6.46255 159.77 6.693 160.07 6.99552 160.282C7.29803 160.494 7.65816 160.608 8.02752 160.609Z"
        fill="#008080"
      />
      <path
        opacity="0.5"
        d="M8.02752 160.609C8.25452 160.608 8.47899 160.562 8.68816 160.473L23.5756 154.546C23.7982 154.459 24.0015 154.33 24.1738 154.165C24.3462 154 24.4842 153.802 24.5799 153.583C24.6755 153.365 24.727 153.129 24.7313 152.891C24.7356 152.652 24.6926 152.415 24.6049 152.193C24.5172 151.971 24.3864 151.768 24.2201 151.597C24.0538 151.426 23.8553 151.289 23.636 151.195C23.4167 151.101 23.1809 151.051 22.9422 151.048C22.7035 151.045 22.4667 151.09 22.2453 151.179L7.35779 157.08C6.91164 157.255 6.5534 157.601 6.36167 158.04C6.16994 158.48 6.16038 158.977 6.33512 159.424C6.46255 159.77 6.693 160.07 6.99552 160.282C7.29803 160.494 7.65816 160.608 8.02752 160.609Z"
        fill="white"
      />
      <path
        d="M39.1509 176.266C39.3769 176.263 39.6007 176.22 39.8116 176.139L66.8443 165.406C67.0669 165.32 67.2702 165.19 67.4425 165.025C67.6149 164.86 67.7529 164.662 67.8485 164.443C67.9442 164.225 67.9957 163.989 68 163.751C68.0043 163.512 67.9613 163.275 67.8736 163.053C67.7859 162.831 67.6551 162.628 67.4888 162.457C67.3225 162.286 67.124 162.149 66.9047 162.055C66.6854 161.961 66.4496 161.911 66.2109 161.908C65.9722 161.905 65.7353 161.95 65.5139 162.039L38.4812 172.736C38.0891 172.891 37.7633 173.178 37.5602 173.548C37.3571 173.917 37.2895 174.346 37.3688 174.76C37.4482 175.175 37.6697 175.548 37.9951 175.816C38.3204 176.085 38.7292 176.231 39.1509 176.23V176.266Z"
        fill="#008080"
      />
      <path
        opacity="0.3"
        d="M39.1509 176.266C39.3769 176.263 39.6007 176.22 39.8116 176.139L66.8443 165.406C67.0669 165.32 67.2702 165.19 67.4425 165.025C67.6149 164.86 67.7529 164.662 67.8485 164.443C67.9442 164.225 67.9957 163.989 68 163.751C68.0043 163.512 67.9613 163.275 67.8736 163.053C67.7859 162.831 67.6551 162.628 67.4888 162.457C67.3225 162.286 67.124 162.149 66.9047 162.055C66.6854 161.961 66.4496 161.911 66.2109 161.908C65.9722 161.905 65.7353 161.95 65.5139 162.039L38.4812 172.736C38.0891 172.891 37.7633 173.178 37.5602 173.548C37.3571 173.917 37.2895 174.346 37.3688 174.76C37.4482 175.175 37.6697 175.548 37.9951 175.816C38.3204 176.085 38.7292 176.231 39.1509 176.23V176.266Z"
        fill="black"
      />
    </svg>
  );
}
